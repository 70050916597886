import {initializeApp} from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  //storageBucket: "ames-870e8.appspot.com",
  //messagingSenderId: "320115896206",
  //appId: "1:320115896206:web:4bd9b67bc4599778963edf",
  //measurementId: "G-ZW7FZHVBEH",
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);


export default firebaseApp;
//const analytics = getAnalytics(app);
