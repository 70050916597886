import React from "react";
import styles from "./footer.module.css";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const amesWhite = "#f0f4f5";

const Footer = ({ onLogout, adminCheck }) => (
  <div>
    <div className={styles.credit}>
      <img
        className={styles.creditLogo}
        height={100}
        width={100}
        src="/images/logo.svg"
      />
    </div>
    <div className={styles.creditTextbox}>
      <div className={styles.creditText}>
        Contact | ara@araiteng.com , 032-566-0829
      </div>
    </div>
    <div className={styles.creditTextbox2}>
      <div className={styles.creditText}>
        Copyright @ ARAIT ENG Inc., all rights reserved
      </div>
    </div>
  </div>
);

export default Footer;
