import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import './index.module.css';
import App from './app';
import AuthService from './service/auth_service'
import { FirebaseApp } from 'firebase/app';
import firebaseApp from './service/firebase';
import ImageUploader from './service/image_uploader';
import Imagefile_input from './components/imagefile_input/imagefile_input';
//import CardRepository from './service/card_repository';
import DataRepository from './service/data_repository';
import {createTheme, ThemeProvider} from '@mui/material'


const authService = new AuthService(firebaseApp);
//const cardRepository=new CardRepository();
const dataRepository = new DataRepository();
const imageUploader = new ImageUploader();
//컴포넌트 자체를 전달해버릴거임. 이렇게해야 업데이트 같은게 되는 상황에 유리함.
//필요한 prop도 전달할 수가 있고, 필수적으로 필요한거는 미리 인젝션 해놨기도하고.
const FileInput= memo(props => (<Imagefile_input {...props} imageUploader={imageUploader}></Imagefile_input>));

//이렇게 안하는 이유는? 이렇게 하면 확장성이 떨어짐.
// const FileInput =(<Imagefile_input {...props} ImageUploader={imageUploader}></Imagefile_input>)

const theme = createTheme({
  typography: {
    fontFamily: "segoe UI"
  }
})


ReactDOM.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={theme}>
      <App
        authService={authService}
        FileInput={FileInput}
        dataRepository={dataRepository}
      />
    </ThemeProvider> */}
    <App
      authService={authService}
      FileInput={FileInput}
      dataRepository={dataRepository}
    />
  </React.StrictMode>,
  document.getElementById("root")
);

