import {
  getDatabase,
  ref,
  set,
  remove,
  onValue,
  off,
  get,
  child,
  DataSnapshot,
  query,
  startAt,
  endAt,
  orderByChild,
} from "firebase/database";

class DataRepository {
  detach(ref) {
    off(ref);
    //console.log('sync off')//, ref)
  }

  syncData(onUpdate, path) {
    const db = getDatabase();
    const dataRef = ref(db, path);
    onValue(dataRef, (snapshot) => {
      const value = snapshot.val();
      //  console.log('ref: ', dataRef)
      //      console.log('value : ', value)
      const convertedValue = value && Object.values(value);
      //console.log('convertedValue : ', convertedValue)
      value && onUpdate(convertedValue);
    });

    //console.log("synced : ");

    return dataRef;
    //return ()=>off(dataRef)
  }

  syncData_date(onUpdate, path, startDate, endDate, orderBy) {
    const db = getDatabase();
    const dataRef = query(
      ref(db, path),
      orderByChild(orderBy),
      startAt(startDate),
      endAt(endDate)
    );
    onValue(dataRef, (snapshot) => {
      //console.log('snapshot :', snapshot)
      const value = snapshot.val();
      //  console.log('ref: ', dataRef)
      const convertedValue = value && Object.values(value);
      //  console.log('cvalue : ', convertedValue)
      //console.log('convertedValue : ', convertedValue)

    onUpdate(convertedValue);
    });

    //console.log("synced : ");

    //console.log(dataRef)
    return dataRef;
    //return ()=>off(dataRef)
  }

  saveData(data, path) {
    const db = getDatabase();
    set(ref(db, path), data);
  }

  removeData(data, path) {
    const db = getDatabase();
    remove(ref(db, path), data);
  }
}

export default DataRepository

