import {getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword} from 'firebase/auth';
import firebaseApp from './firebase';

class AuthService {
    constructor(){
        this.firebaseAuth=getAuth()
  //      this.googleprovider = new GoogleAuthProvider();
    }
    login(email, password){
    //    const authProvider = new GoogleAuthProvider();
    //    return signInWithPopup(this.firebaseAuth, authProvider);
      return signInWithEmailAndPassword(this.firebaseAuth, email, password)
    }

    logout(){
        this.firebaseAuth.signOut();
    }

    onAuthChange(onUserChanged){
        this.firebaseAuth.onAuthStateChanged(user=>{
            onUserChanged(user);
        })
    }
}
export default AuthService;
