import React, {useEffect, useState} from 'react';
import { LicenseInfo } from "@mui/x-license-pro";
import { BrowserRouter, Link, Route, Routes, Navigate} from 'react-router-dom';
import Login from "./components/login/login";

//#region
import Home from './components/home/home';

//#endregion

import styles from './styles/app.module.css';


LicenseInfo.setLicenseKey(
  "3dd14594c4d28571351574f8a08a9959Tz03ODYyNixFPTE3MzE0NjE3MjQwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

function App({FileInput, authService, dataRepository}) {
  const [isLoggedIn, setLog]=useState(null)
  //const uid3 = "ia7cuXlaeRcP6eKtwAR8Mvjn1QY2";
  // const uid2 = "PkF4cfb0qHSqxI9r6u4Eilvc9dw1";
  // const uid = "QOJRjM4xyhMXgLNxcEo89p3rB0D3";
  const authrized_users = [
    "aL7MKGiWLHYdbd1Ojqk7tbxdhg12",
    "Za8NNWFvneY3UYms1IniM0fLmXT2",
    "kgzKV07u9ffGzGfEXkXOvZOAuv63",
  ];
  const admin_users = [
    "aL7MKGiWLHYdbd1Ojqk7tbxdhg12",
    "Za8NNWFvneY3UYms1IniM0fLmXT2",
  ];
  const authCheck = (userId)=> {
    //console.log(authService.firebaseAuth.lastNotifiedUid);
    if(isLoggedIn == true){
      return userId.includes(authService.firebaseAuth.lastNotifiedUid)
        ? true
        : false; 
    }
    else{
      return false
    }
  }

  const adminCheck = (userId) => {
    return admin_users.includes(userId)? true : false
  };

  useEffect(()=>{
    authService.firebaseAuth.onAuthStateChanged(
      (user)=>{
        if(user){
          setLog(true);
          //console.log(isLoggedIn)
          
          //console.log(authService.firebaseAuth.lastNotifiedUid);
     //     authCheck(authrized_users);
        }
        else{
          setLog(false)
          //console.log(isLoggedIn);
        }
      }
    )
  //  console.log(adminCheck(authService.firebaseAuth.lastNotifiedUid));

  }, [isLoggedIn])



  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
           
                authCheck(authrized_users) == false ? (
                  <Login
                    authService={authService}
                    dataRepository={dataRepository}
                    adminCheck={adminCheck(
                      authService.firebaseAuth.lastNotifiedUid
                    )}
                  ></Login>
                ) : (
                  <Navigate replace to="/admin"></Navigate>
                )
              
            }
          ></Route>
          {/* <Route
            path="/home"
            element={
              authCheck(authrized_users) == true ? (
                <Home authService={authService}></Home>
              ) : (
                <Navigate replace to="/"></Navigate>
              )
            }
          ></Route> */}
        
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;