import React from 'react';
import styles from './header.module.css'
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const amesWhite = "#f0f4f5"

const Header = ({ onLogout, adminCheck }) => (
  <div className={styles.headerWrapper}>
    <div className={styles.companyName}>ARAIT ENG Inc.</div>
    <div className={styles.companyNameSub}>주식회사 아라아이티이엔지</div>

    <div className={styles.companyNameSub3}>
      인천광역시 서구 청라에메랄드로 94, 6F
    </div>
    <div className={styles.companyNameSub2}>
      Intelligent Engineering R&D Center
    </div>
    {/* <div className={styles.logout} onClick={onLogout}>
      <LogoutOutlinedIcon fontSize="large">로그아웃</LogoutOutlinedIcon>
      <div className={styles.logouttext}>로그아웃</div>
    </div> */}
  </div>
);

export default Header;