import React, { memo, useRef, useState } from 'react';
import styles from './imagefile_input.module.css';

const Imagefile_input = memo(
  ({imageUploader, name, onFileChange}) => {
        const [loading, setLoading] = useState(false)

        const inputRef = useRef();
        const onButtonClick = (e) => {
            e.preventDefault()
            inputRef.current.click();
        };
//async 사용하지 않은 버전이고,
        // const onChange=(event)=>{
        //     //여기에 event.target.files가 파일 URL임.
        //     console.log(event.target.files[0])
        //     //이미지 파일 URL을 이미지 업로더에 전달함.
        //     //프로미스가 리턴되기 때문에 then~ 이걸로 처리하는게 가능.
        //     imageUploader.upload(event.target.files[0]).then(console.log)
        // }
        const onChange = async event => {
          
          //여기에 event.target.files가 파일 URL임.
         // console.log(event.target.files[0]);
          //이미지 파일 URL을 이미지 업로더에 전달함.
          //프로미스가 리턴되기 때문에 then~ 이걸로 처리하는게 가능.
          setLoading(true);
          const uploaded = await imageUploader.upload(event.target.files[0]);
          setLoading(false);

          onFileChange(
              {
                  name: 'fileName',
                  url: 'url'
              }
          )
        };

        // 아래 코드에서 button을 누르지만, 실제로는 Input을 누른 것과 같은 효과가 나옴.
    return (
      <div className={styles.container}>
        <input
          ref={inputRef}
          className={styles.input}
          type="file"
          accept="image/*"
          name="file"
          onChange={onChange}
        />
        {!loading && (
          <button className={`${styles.button} ${name ? styles.pink : styles.grey}`} onClick={onButtonClick}>
            {name || "No file"}
          </button>
        )}

        {loading && <div className={styles.loading}></div>}
      </div>
    );
}
)
       

export default Imagefile_input

