

class ImageUploader{
    wait = (time) => new Promise((resolve)=>setTimeout(resolve, time));

    async upload(file){
        console.log('upload Start!')
        await this.wait(5000)
        console.log("upload complete!");
        
        return 'file';

    }
}

export default ImageUploader;